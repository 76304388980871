$hovered-bg-color: #f1f1f1;

.dropdown,
.body {
  cursor: pointer;
  border: 1px solid #c4c4c4;
  z-index: 44;
}

.dropdown {
  max-width: 225px;
  height: 32px;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    padding: 6.5px 13px 6.5px 12px;
    background-color: #fff;
    border-radius: 3px;
    width: 175px;

    .arrow {
      position: absolute;
      right: 10px;
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .isOpen {
      transform: rotate(180deg);
    }
  }

  .body {
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;
    position: relative;

    .item {
      padding: 7px 13px;

      &:hover {
        background-color: $hovered-bg-color;
      }
    }

    .selectedValue {
      background-color: $hovered-bg-color;
    }
  }
}

.wide {
  width: 100%;
  max-width: unset;
  margin-bottom: 8px;

  .header {
    justify-content: space-between;
  }
}

@media (max-width: 800px) {
  .dropdown {
    width: 100%;
    max-width: unset;
    margin-bottom: 8px;

    .header {
      justify-content: space-between;
      width: unset;
      padding-left: 16px;
    }
  }
}
