.Tag {
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 60px;
  display: inline-block;
  border: none;
  
  &.Large {
    font-size: 14px;
    height: 32px;
    padding: 8px 15px;
  }

  &Button {
    cursor: pointer;
    opacity: 0.3;
    box-sizing: border-box;
    border: none;
    &.TagButtonActive {
      opacity: 1;
    }
  }
}
