.overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  align-items: center;
  justify-content: center;
  z-index: 45;

  .wrapper {
    display: flex;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    position: relative;
    z-index: 80;
    border-radius: 4px;

    .header {
      .hadBorder {
        border-bottom: 1px solid #d9d9d9;
      }
    }

    .withoutHeader {
      display: none !important;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 13px;
      padding-bottom: 8px;

      .title,
      .blackTitle,
      .grayTitle {
        padding: 10px 28px;
        font-size: 16px;
        color: #28587b;
      }

      .blackTitle {
        color: #000;
      }

      .grayTitle {
        color: #9e9e9e;
      }

      .closeBtn {
        padding-right: 15px;
        cursor: pointer;
        width: 16px;
        height: 34px;
      }
    }

    .body {
      padding: 16px 28px;
      overflow: auto;
    }
  }

  .small {
    width: 560px;
  }

  .customDecisionTable {
    width: 100%;
    height: 635px;
  }

  .bobModal {
    max-width: 1024px;
    width: 100%;
    margin: 20px;
  }

  .large {
    max-width: 1359px;
    max-height: 800px;
    height: 100%;
    width: 100%;
    margin: 20px;
  }

  .medium {
    max-width: 1359px;
    max-height: 725px;
    width: 100%;
    height: 100%;
    margin: 20px;
  }

  .hasBorder {
    border-bottom: 1px solid #d9d9d9;
  }

  .closeBtnWithoutHeader {
    padding-right: 15px;
    cursor: pointer;
    width: 25px;
    height: 32px;
    position: absolute;
    right: 0;
    top: -45px;
  }
}

.background {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 46;
}

@media (max-width: 960px) {
  .overlay {
    .bobModal {
      width: 100%;
      height: 100%;
      position: fixed;
      max-width: unset !important;
      top: 0;
      scrollbar-width: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0 !important;

      .header {
        padding-top: 12px;
        padding-bottom: 7px;
      }

      .body {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .overlay {
    .removeBorderMobile {
      border-bottom: none;

      .blackTitle {
        padding: 10px 16px !important;
      }
    }
  }
}

@media (max-width: 750px) {
  .overlay {
    .small {
      max-width: 400px;
      width: 100% !important;
      margin: 25px;
    }

    .fullSizeMobile {
      max-width: unset;
    }
  }
}
