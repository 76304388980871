.CopyButton {
  cursor: pointer;
  font-size: 12px;
  color: #28587b;

  & img {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }

  &.Copied {
    cursor: default;
    color: #00a825;
  }

  &:not(.Copied):hover {
    text-decoration: underline;
  }
}
