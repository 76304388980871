$headerHeight: 48px;

.ToolHeader,
.uxcoreToolHeader {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  height: $headerHeight;
  padding: 0 0 0 2.5rem;
  transition: 0.3s;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  z-index: 2;
  box-sizing: border-box;
  justify-content: space-between;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .LinkWrapper {
    font-size: 26px;
    display: flex;

    .logo {
      padding-top: 12px;
    }

    .Links {
      display: flex;
      margin-left: 50px;
    }
  }

  .MenuItem {
    font-size: 16px;
    display: flex;
    cursor: pointer;
    color: #515151;
    text-decoration: none;
    margin-right: 6px;
    height: $headerHeight;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    padding: 4px 12px 0;

    .bob {
      opacity: 85%;
    }

    & svg,
    .bob {
      margin-right: 10px;
    }

    &:hover {
      color: #5b88bd;

      .bob {
        opacity: 100%;
      }

      svg {
        path {
          fill: #5b88bd;
        }
      }
    }

    &.Active {
      color: #5b88bd;
      border-bottom-color: #5b88bd;

      & svg path {
        fill: #5b88bd;
      }
    }
  }

  & .actions {
    font-size: 18px;
    box-sizing: border-box;
    right: 25px;
    z-index: 2;
    display: flex;
    align-items: center;

    &.authorized {
      right: 0;
      display: flex;
      align-items: center;
    }

    & > * {
      display: inline-block;
    }

    & .toggleLanguage {
      text-align: center;
      font-size: 16px;
      padding-right: 10px;

      & a {
        cursor: pointer;
        text-decoration: none;
        color: inherit;
      }

      &.en a:nth-child(1) {
        font-weight: 700;
      }

      &.ru a:nth-child(3) {
        font-weight: 700;
      }

      & .toggleLanguageDivider {
        margin: 0 6px;
        position: relative;
        top: 1px;
      }
    }
  }

  & .UsefulLinksWrapper {
    display: flex;
    right: 100px;
    height: $headerHeight;
    top: 0;

    &.authorized {
      right: 256px;
    }

    & .MenuItem {
      position: relative;
      margin: 0;

      &:hover > div {
        display: block;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      width: 1px;
      height: 32px;
      top: 8px;
      background-color: #c4c4c4;
    }
  }

  .headerTooltipUxCore,
  .headerTooltipUxcg {
    position: absolute;
    bottom: -35px;
    width: fit-content;
    padding: 5px 10px 5px 16px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transform: translateY(-50px);

    .closeBtn {
      all: unset;
      cursor: pointer;
      margin-left: 10px;

      > svg {
        fill: #fff;
        width: 10px;
        height: 10px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
    }
  }

  .headerTooltipUxCore {
    background-color: #77a34b;
    margin-left: 218px;
    animation-iteration-count: infinite;
    animation-name: bounce, pulsate;
    animation-duration: 2s, 1.5s;
    animation:
      bounce 1.15s forwards 1s,
      pulsate 1.5s ease-in-out infinite;
    animation-timing-function: ease, ease;

    &:after {
      border-color: transparent transparent #77a34b transparent;
    }
  }

  .headerTooltipUxcg {
    margin-left: 125px;
    background-color: #a36aa4;
    animation-iteration-count: infinite;
    animation-name: bounce, pulsate-purple;
    animation-duration: 2s, 1.5s;
    animation:
      bounce 1.15s forwards 1s,
      pulsate-purple 1.5s ease-in-out infinite;
    animation-timing-function: ease, ease;

    &:after {
      border-color: transparent transparent #a36aa4 transparent;
    }
  }

  @keyframes pulsate {
    0% {
      box-shadow: 0 0 5px #42c256;
    }

    50% {
      box-shadow:
        0 0 15px #42c256,
        0 0 5px #42c256;
    }

    100% {
      box-shadow: 0 0 5px #42c256;
    }
  }

  @keyframes pulsate-purple {
    0% {
      box-shadow: 0 0 5px #662369ff;
    }

    50% {
      box-shadow:
        0 0 15px #662369ff,
        0 0 5px #662369ff;
    }

    100% {
      box-shadow: 0 0 5px #662369ff;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@media (max-width: 1330px) {
  .ToolHeader {
    .UsefulLinksWrapper {
      .MenuItem {
        span {
          display: none;
        }

        svg {
          margin-right: unset;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .ToolHeader {
    padding: 0 0 0 15px;

    .LinkWrapper {
      .Links {
        margin-left: 15px;
      }
    }
  }
}

@media (max-width: 1010px) {
  .ToolHeader {
    display: block;
    position: static;
    padding: 0;
    height: auto;
    background-color: transparent;
    box-shadow: none;

    .mobile {
      display: block;
    }

    .LinkWrapper,
    .UsefulLinksWrapper {
      display: none;
    }

    .headerTooltipUxCore,
    .headerTooltipUxcg {
      display: none;
    }

    & .PageSwitcherContainer {
      padding: 16px 16px 0;
    }
  }
}

@media (max-width: 920px) {
  .UsefulLinksWrapper {
    &.authorized {
      right: 120px !important;
    }
  }

  .userContainer {
    .userName {
      display: none;
    }
  }
}
