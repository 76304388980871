.ContactUs {
  & .Footer {
    & .Content {
      font-size: 16px;
      color: #000;
      line-height: 150%;

      & a {
        color: inherit;
      }
    }

    & .Buttons {
      text-align: right;

      & button + button {
        margin-left: 8px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .ContactUs {
    & .Footer {
      & .Content {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 800px) {
  .ContactUs {
    & .Footer {
      & .Content {
        font-size: 16px;
      }

      & .Buttons {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}
