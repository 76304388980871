.userContainer {
  position: relative;
  width: auto;

  .user,
  .active {
    height: 42px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 5px 20px 0px 20px;

    &:hover {
      background: #f1f1f1;

      .userName {
        color: #5b88bd;
      }
    }

    .image {
      border-radius: 50%;
      overflow: hidden;
    }

    .userName {
      font-style: normal;
      font-weight: 400;
      font-size: 14.3437px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .active {
    background: #f1f1f1;

    .userName {
      color: #5b88bd;
    }
  }

  .dropdown {
    z-index: 10;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    position: absolute;
    top: 100%;
    text-align: end;
    height: auto;
    width: -webkit-fill-available;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    right: 10px;
    width: 100%;
    min-width: 170px;
    display: flex;
    flex-direction: column;

    .savedPersonas,
    .myProfile,
    .settings,
    .logOut,
    .userNameDropdown {
      height: 17px;
      display: flex;
      align-items: center;
      z-index: 3;
      text-align: justify;
      padding: 8px 0 7px 16px;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .myProfile,
    .settings {
      a {
        text-decoration: none;
        color: #000;
      }
    }

    .userNameDropdown {
      display: none;
    }
  }

  .showed {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1330px) {
  .userContainer {
    .user {
      .userName {
        display: none;
      }
    }
  }
}

@media (max-width: 1110px) {
  .userName {
    display: none;
  }
  .userContainer {
    .dropdown {
      right: 0;
      z-index: 100;

      .savedPersonas {
        &:hover {
          &:before {
            border-top-left-radius: unset;
            border-top-right-radius: unset;
          }
        }
      }

      .userNameDropdown {
        display: block;
        color: #9e9e9e;
      }
    }

    .user {
      padding: 5px 15px 0px 15px;

      .image {
        margin-right: unset;
      }
    }
  }
}
