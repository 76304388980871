.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  height: 350px;

  .title {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #28587b;
  }

  .link {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px 16px;
    height: 32px;
    background-color: #fff;
    cursor: pointer;
    color: #000;
    transition: 0.2s;
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
  }
  .description {
    text-align: center;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 11px;

    .cancelBtn {
      height: 46px;
    }
  }
}

.loginWrapper {
  width: 600px !important;
}
.loginBody {
  padding: 0 28px 50px 28px !important;
  & h1 {
    line-height: 10px !important;
  }
}
