.Contributors {
  color: #000;

  & .Content {
    font-size: 14px;
    margin-bottom: 20px;
  }

  & .Currencies {
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    padding: 15px 12px;
    margin-bottom: 35px;

    & .Account {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      & + .Account {
        margin-top: 12px;
      }

      & .Coin {
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        width: 84px;

        & > *:not(span) {
          font-size: 0;
        }

        & span {
          margin-left: 10px;
        }
      }

      & .Number {
        margin-right: 10px;
        color: #9e9e9e;
        font-size: 12px;
      }

      &:nth-of-type(3) .Coin:after {
        content: '(ERC20)';
        font-size: 12px;
        color: #9e9e9e;
        position: absolute;
        bottom: -8px;
        right: 10px;
      }
    }
  }

  & .OurTeam {
    & img {
      position: relative;
      top: 3px;
    }

    & + ul {
      margin-top: 8px;
      margin-bottom: 30px;
      padding-left: 26px;
    }
  }

  & .Author {
    font-size: 14px;

    & a {
      color: #000;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .Footer {
    margin-top: 20px;

    & .Buttons {
      text-align: right;
    }
  }
}

@media (max-width: 800px) {
  .Contributors {
    & .Currencies {
      border: none;
      padding: 0;

      & .Account .Number {
        display: none;
      }
    }

    & .Footer {
      & .Buttons {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}
